import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import boletin from "../../img/BoletinIMG.png";
import previewcorte from "../../img/previewcorte.jpeg";
import RepoPreventivoEstudiante from "../Reportes/CortePreventivo/cortePreventivoEstudiante";
import preventiveNoteService from "../../services/preventiveNoteService";
import util from '../../utils/generalFunctions';
import Swal from "sweetalert2";
import Loading from "../../components/Loading";
import RepoBoletinIntermedio from "../Reportes/Boletines/boletin-intermedio";
import RepoBoletinDiurna from "../Reportes/Boletines/boletin-diurna";
import estudiantesService from "../../services/estudiantesService";
import reportsService from "../../services/reportsService";
import academicService from "../../services/academicService";
import { Button } from "react-bootstrap";
import moment from "moment";
import RepoBoletin from "../Reportes/Boletines/boletin";
import RepoBoletinDimension from "../Reportes/Boletines/boletin-dimension";

const DashboardReporteEstudiantes = () => {
    const studentInfo = util.getStudentInformation();
    const schoolYearId = studentInfo.schoolYearId;
    const [reporteSelected, setReporteSelected] = useState(null); 
    const [infCortePreventivo, setInfCortePreventivo] = useState(null); 
    const [infCorteIntermedio, setInfCorteIntermedio] = useState(null); 
    const [infBoletin, setInfBoletin] = useState(null); 
    const [boletinValorativo, setBoletinValorativo] = useState(null);
    const [boletinDimension, setBoletinDimension] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [periodos, setPeriodosList] = useState([]);
    const [schoolYearList, setSchoolYearList] = useState([]);

    const initData ={
        schoolYearId: 0,
        academicPeriodId:0
    };
    const [formData, setFormData] = useState(initData);

    const volverLista =  async () => {
        setReporteSelected(null);
        setInfCortePreventivo(null);
        setInfBoletin(null);
        setBoletinValorativo(null);
        setBoletinDimension(null);
    };

     const cargarPermisos = async () =>{
       
    }


    const cargarFiltroReporte = async () =>{
        const data = await reportsService.getPermisosEstudiantes(
            studentInfo.studentId,
            studentInfo.schoolYearId,
            formData.academicPeriodId
          ); // Obtenemos los estudiantes de tu sistema
            let permiso = false;
            switch(reporteSelected){
                case 3:
                    // <option key="0" value="0">Seleccione</option>
                    //             <option key="1" value="1">Boletin</option>
                    //             <option key="2" value="2">Perfil de notas</option>
                    //             <option key="3" value="3">Corte Intermedio</option>
                    //             <option key="4" value="4">Corte Preventivo</option>
                     permiso = data.find(d=>d.reportTypeId===4 && d.visible===true);
                     if(permiso){
                        generarReportePreventivo();
                     }else{
                        showMensajePermiso();
                     }
                break;
                case 4:
                     permiso = data.find(d=>d.reportTypeId===3 && d.visible===true);
                     if(permiso){
                        generarReporteIntermedio();
                     }else{
                        showMensajePermiso();
                     }
                break;
                case 5:
                    permiso = data.find(d=>d.reportTypeId===1 && d.visible===true);
                    if(permiso){
                        generarBoletin();
                    }else{
                       showMensajePermiso();
                    }
                break;
            }
    }

    const generarReportePreventivo = async () =>{
        setInfCorteIntermedio(null);
        setInfBoletin(null);
        setShowLoading(true);
        const data = await preventiveNoteService.getPreventivoNotasCourseReport(
            studentInfo.studentId,
            studentInfo.courseId,
            studentInfo.schoolYearId,
            formData.academicPeriodId
          ); // Obtenemos los estudiantes de tu sistema
          if(data && data.length>0){
            const info = data[0];
            const arrayValidation = info.schoolSubjects.filter(s=>s.evaluationProcess.filter(e=>e.evaluativeScale).length > 0);
            if(arrayValidation.length>0){
                setInfCortePreventivo(info);
                setReporteSelected(3);
            }else{
                Swal.fire({
                    title: "Buen trabajo!",
                    text: "No has sido reportado en ninguna asignatura!",
                    icon: "success"
                    });
            }
          }else{
           Swal.fire({
                title: "Buen trabajo!",
                text: "No has sido reportado en ninguna asignatura!",
                icon: "success"
                });
                setInfCortePreventivo(null); 
          }
          setShowLoading(false);
    }

    const generarReporteIntermedio = async () =>{
        setShowLoading(true);

        const data = await estudiantesService.getEstudianteBoletinIntermedio(
            studentInfo.studentId,
            studentInfo.courseId,
            studentInfo.schoolYearId,
            formData.academicPeriodId
          ); // Obtenemos los estudiantes de tu sistema
          if(data){
            setInfCorteIntermedio(data);
            setReporteSelected(4);
            setInfCortePreventivo(null); 
            setInfBoletin(null);
          }
          setShowLoading(false);
    }

    const generarBoletin = async () =>{
        setShowLoading(true);
        if(String(studentInfo.schoolYearId)==="11" || String(studentInfo.schoolYearId)==="12"){
            const data = await estudiantesService.getEstudianteBoletin(
                studentInfo.studentId,
                studentInfo.courseId,
                studentInfo.schoolYearId,
                formData.academicPeriodId,
                1
              ); // Obtenemos los estudiantes de tu sistema
              console.log(data);
              setInfBoletin(data);
              setReporteSelected(5);
              setBoletinValorativo(null);
              setBoletinDimension(null);
              setInfCortePreventivo(null); 
              setInfCorteIntermedio(null);
              setShowLoading(false);
        }else{
            if(Number(studentInfo.educationLevelId)>1){
                const data = await estudiantesService.getEstudianteBoletinValorativo(
                    studentInfo.studentId,
                    studentInfo.courseId,
                    studentInfo.schoolYearId,
                    formData.academicPeriodId,
                    false
                  ); // Obtenemos los estudiantes de tu sistema
                  if(data && data.length>0){
                    setBoletinValorativo(data[0]);
                    setBoletinDimension(null);
                    setReporteSelected(5);
                    setInfCortePreventivo(null); 
                    setInfCorteIntermedio(null);
                  }
                  setShowLoading(false);
            }else{
                const data = await estudiantesService.getEstudianteBoletinDimension(
                    studentInfo.studentId,
                    studentInfo.courseId,
                    studentInfo.schoolYearId,
                    formData.academicPeriodId
                  ); // Obtenemos los estudiantes de tu sistema
                  console.log(data);
                  setBoletinValorativo(null);
                  setReporteSelected(5);
                  setBoletinDimension(data[0]);
                  setInfBoletin(null);
                  setInfCortePreventivo(null); 
                  setInfCorteIntermedio(null);
                  setShowLoading(false);
            }
        }          
    }

    const showMensajeInfo = () => {
        toast.info("Este reporte se habilitara proximamente", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
    }

    const showMensajePermiso = () => {
        toast.info("Reporte no habilitado para este período", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
    }

    const showMensajeInfoEst = () => {
        toast.info("Estamos en procesos de finalización  de periodo", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
    }

    useEffect(() => {
        fetchSchoolYears();
    }, []);

    const fetchSchoolYears = async () => {
        try {
            const yearsResp = await academicService.getYearsByStudent(studentInfo.studentId);
            setSchoolYearList(yearsResp);
            if(yearsResp.length>0){
                setFormData(prevState => ({
                    ...prevState,
                    schoolYearId: yearsResp[yearsResp.length-1].schoolYearId
                }));
            }
        } catch (error) {
            console.error("Error al obtener listas de formulario:", error);
        }
    };

    useEffect(() => {
        cargarPeriodos();
    }, [formData.schoolYearId]);

    const cargarPeriodos = async () => {
        try {
            if(formData.schoolYearId>0){
                const schoolYear = await academicService.getYearById(formData.schoolYearId);              
                if(schoolYear){
                    const peri = schoolYear.periods.filter(p=>p.isRecuperation===0 || !p.isRecuperation);
                    setPeriodosList(peri);
                    let selectedP = null;
                    for (let i = 0; i < peri.length; i++) {
                        const p = peri[i];
                        selectedP = p;
                        const today = moment().toDate();
                        const start = moment(p.startDate).toDate();
                        const end = moment(p.endDate).toDate();
                        if((today >= start) && (today <= end)){
                            selectedP = p;
                            break;
                        }else if(today <= start){
                            selectedP = p;
                            break;
                        }
                    }
                    if(selectedP!==null){
                        setFormData(prevState => ({
                            ...prevState,
                            academicPeriodId: selectedP.academicPeriodId
                        }));
                    }
                }  
            }else{
                setFormData(prevState => ({
                    ...prevState,
                    academicPeriodId: 0
                }));
            } 
        } catch (error) {
             console.error("Error al obtener información de año escolar:", error);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

   
    // useEffect(() => {
        
    // }, []);
      
    return (
        <>

        {showLoading && <Loading />}

        {!showLoading && reporteSelected===null && (
          <div className="student-table-container p-3 filter-container">
            <h1>Informes</h1>
            <p>Selecciona el informe que quieres visualizar.</p>
            
                <div className="row">

                {!showLoading &&  (
                    <div class="col-12 col-md-6 col-lg-3">
							<div class="card">

								<img class="card-img-top" src={boletin} alt="Boletin" />

								<div class="card-header px-4 pt-4">
									<h5 class="card-title mb-0">Boletin</h5>
									<div class="badge bg-warning my-2"></div>
								</div>
								<div class="card-body px-4 pt-2">
									<p>Genera e imprime tu boletin.</p>
								</div>
			    	  		    <div className="d-grid">
                                    <button className="btn btn-primary" onClick={()=>{ setReporteSelected(5); }}> Ver Informe </button>
                                </div> 
							</div>
					</div>
                )}

                    <div class="col-12 col-md-6 col-lg-3">
							<div class="card">

								<img class="card-img-top" src={boletin} alt="Corte Intermedio" />

								<div class="card-header px-4 pt-4">
									<h5 class="card-title mb-0">Corte intermedio</h5>
									<div class="badge bg-warning my-2"></div>
								</div>
								<div class="card-body px-4 pt-2">
									<p>Genera e imprime el informe de corte intermedio.</p>
								</div>
			    	  		    <div className="d-grid">
                                    <button className="btn btn-primary" onClick={()=>{ setReporteSelected(4); }}> Ver Informe </button>
                                </div> 
							</div>
					</div>

                    <div class="col-12 col-md-6 col-lg-3">
							<div class="card">

								<img class="card-img-top" src={previewcorte} alt="Corte Preventivo" />

								<div class="card-header px-4 pt-4">
									<h5 class="card-title mb-0">Corte preventivo</h5>
									<div class="badge bg-warning my-2"></div>
								</div>
								<div class="card-body px-4 pt-2">
									<p>Genera e imprime el informe de corte preventivo.</p>
								</div>
			    	  		    <div className="d-grid">
                                    <button className="btn btn-primary" onClick={()=>{ setReporteSelected(3); }}> Ver Informe </button>
                                </div> 
							</div>
					</div>

                    
                </div>
          </div>
        )}

        {!showLoading && reporteSelected>0 && (
            <div className="student-table-container p-3 mt-3 mb-3 non-printable">
                <div className="student-table-controls mb-3 d-flex justify-content-between align-items-center">

                    <button className="btn btn-secondary"  onClick={()=>{volverLista()}}><i className="fas fa-arrow-left"></i>Volver</button>

                    <div className="col-md-2 mb-2">
                        <label htmlFor="schoolYearId" className="form-label">Año escolar:</label>
                        <select className="form-control form-select" 
                        id="schoolYearId" name="schoolYearId" value={formData.schoolYearId} onChange={handleChange}>
                            <option value="0" >Seleccione</option>
                            {schoolYearList.map((schoolYear) => (
                                <option key={schoolYear.schoolYearId} value={schoolYear.schoolYearId} >
                                    {schoolYear.year}
                                </option>
                            ))}
                        </select>
                    </div>
                    
                    <div className="col-md-2 mb-2">
                        <label htmlFor="academicPeriodId" className="form-label">Periodo:</label>
                        <select className="form-select flex-grow-1" name="academicPeriodId" value={formData.academicPeriodId} onChange={handleChange}>
                                <option value="0">Seleccione un periodo.</option>
                                {periodos.map((periodo) => (
                                    <option key={periodo.academicPeriodId} value={periodo.academicPeriodId}>
                                        {periodo.periodName}
                                    </option>
                                ))}
                            </select>
                    </div>
                    <div className="col-md-2 mb-1 margin-top-45">
                                <Button onClick={cargarFiltroReporte} className="ml-2">Generar reporte</Button>
                    </div>
            </div>
        </div>
        )}

        {!showLoading && reporteSelected===3 && infCortePreventivo && (
            <div>
                <RepoPreventivoEstudiante
                reporte={infCortePreventivo}
                /> 
            </div>
        )}

        {!showLoading && reporteSelected===5 && (
            <div>
                {infBoletin && (
                    <RepoBoletin
                        boletin={infBoletin}
                    /> 
                )}

                {boletinValorativo && (
                    <RepoBoletinDiurna
                        boletin={boletinValorativo}
                    /> 
                )}

                {boletinDimension && (
                    <RepoBoletinDimension
                        boletin={boletinDimension}
                    /> 
                )}
            </div>
        )}

        {!showLoading && reporteSelected===4 && infCorteIntermedio && (
            <div>
                <RepoBoletinIntermedio
                    boletin={infCorteIntermedio}
                    periodo={{periodName:periodos.find(p=>p.academicPeriodId===formData.academicPeriodId)?.periodName}}
                /> 
            </div>
        )}

        </>
    );
  };
  
  export default DashboardReporteEstudiantes;